import React from 'react'
import {ButtonGroup, Row, Col} from "react-bootstrap";
import User from "./../User";
import Credit from "./../Credit";
import CreditMobile from "./../Credit/Mobile";
import Logo from "./../Logo";
import Chat from "./../Chat";
import RealTime from "./../RealTime";
import MobileMenu from "./../Menu/MobileMenu";
import Notifications from "./../Notification";
import {isMobile, Event, BRAND} from "../../../../Helper";
import Wallett from '../../../Components/User/Wallett';
import Logout from "./../../Auth/Logout";
import Logout2 from "./../../Auth/Logout2";

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            header: null,
            show: true,
            showComponent: false
        }
        this.handleResize = this.handleResize.bind(this);
        this.handleShow = this.handleShow.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(){
        if(!isMobile()){
            this.setState({ header: this.desktop() })
        }
        else {
            this.setState({ header: this.mobile() })
        }
    }

    handleShow(e){
        console.log('clicked');
        this.setState({
            showComponent: true,
          });
    }

    collapse = () =>{
        this.setState({ show: !this.state.show })
        Event.emit('toggle_sidebar', this.state.show);
    }

    desktop = () => {
        const { t } = this.props;
        return (
            <div className="col-12 topbar" id={'topbar'}>
                            <div>
                            <Logo brand={BRAND} />
                            <nav className="navbar-custom">
                                <ul className="list-unstyled topbar-nav float-right mb-0 ml-auto">
                               
                                <li className={"guest-dropdown mt-1"}>
                                    <Credit t={t} />
                                </li>
                                 
                                <li className='mt-2 ml-2'>
                                    <Wallett t={t} />
                                </li>

                                <li className={"guest-dropdown mt-1"}>

                                    <ul style={{padding: '0 5px'}}>
                                        <ButtonGroup>
                                            <User t={t}/>
                                            {/* <Notifications t={t} /> */}
                                        </ButtonGroup>
                                    </ul>
                                </li>

                                <li className='p-0 m-0'>
                                         <Logout t={t} />
                                </li>
                                
                                </ul>
                            </nav>
                            </div>
                           
                            
                        </div>
           
        );
    }

    mobile = () => {
        const { t } = this.props;
        return (
                <div className="topbar" id={'topbar'}>
                    <Logo brand={BRAND} />
                    <nav className="navbar-custom">
                   
                        <ul className="list-unstyled topbar-nav float-right mb-0 mr-0" style={{marginTop: '7px'}}>
                            {/* <RealTime t={t} /> */}
                          
                            <CreditMobile t={t} />
                           <li className='mt-2'>
                           <Wallett t={t} />
                           </li>
                           <li>
                           <ButtonGroup>
                                <User t={t}/>
                            </ButtonGroup>
                           </li>
                           {/* <li className='p-0 m-0'>
                                         <Logout2 t={t} />
                            </li> */}
                        </ul>
                    </nav>
                </div>
        );
    }

    render() {
        return this.state.header;
    }
}

export default Header;