import React, {Component} from "react";
import Scripts from "./Script";
import {Alert} from "react-bootstrap";

class AutoBet extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let { isLogged } = this.props;
        return <>
            { isLogged ?
                <Scripts/>
                :
                // <Alert className="alert-purple text-darker rounded-0 mx-2 mt-4">
                //     You most be logged to use this option.
                // </Alert>
                // <div class="col-12 m-auto d-flex no-left-padding">
                <div class="">
                    <span class="ml-1">
                        {/* <button className="btn btn-block btn-auto text-white py-2">Auto Bet</button> */}
                        <button className="btn-auto">Auto Bet</button>
                    </span>
                </div>
                   
            }
                {/* <Scripts isLogged={isLogged}/> */}
        </>;
    }
}

export default AutoBet;