import React from 'react'
import { Row, Col, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";
import storage from "../../../Storage";
import { SITE, getElement, sendNotfication } from "../../../Helper";
import Terms from "./Terms";
import List from "./List";
import Modal from "react-bootstrap/Modal";
import { useLocation, Redirect } from 'react-router-dom';
import Register from "../Auth/Register";

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            logged: false,
            show: false,
            code: storage.getKey('referral_code') ? storage.getKey('referral_code') : null,
            ref_code: ''
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillMount() {
        if (this.state.token !== null) {
            this.setState({ logged: true });
        }

        if (this.props.match) {
            this.ref_code = this.props.match.params.code;
        }

        storage.setKey('ref_code', this.ref_code);

        if (this.ref_code) {
            // Redirect to the index page
            // return <Redirect to="/" />;
            this.props.history.push('/');
        }
    };

    // getRefCode() {
    //     const location = useLocation();
    //     const searchParams = new URLSearchParams(location.search);

    //     const codex = searchParams.get('code');
    //     console.log(code);
    // }

    copyAddress(id) {
        var address = getElement('#' + id);
        address.select();
        address.setSelectionRange(0, 99999); /*For mobile devices*/
        document.execCommand("copy");
        const { t } = this.props;

        return sendNotfication('Refferal Code Copied', 'success', 'bottom-left');
    }

    handleClose() {
        this.setState({ show: false, effect: 'zoomOut' });
    }

    handleShow() {
        this.setState({ show: true, effect: 'zoomIn' });
    }

    render() {
        // let { isLogged } = this.props;
        let { logged } = this.state;

        let base_ref_url = window.location.origin + '/affiliate/' + this.state.code;
        return (
            <>
                <span onClick={this.handleShow} className={"dropdown-item"}>
                    <i className="dripicons-user-group text-muted mr-2 text-drop" />
                    Refer & Earn
                </span>
                <Modal
                    size="md"
                    backdrop="static"
                    centered={true}
                    show={this.state.show}
                    onHide={this.handleClose}
                    aria-labelledby="help-md-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header className="Header">
                        Referral Program
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'} />
                        </button>
                    </Modal.Header>
                    <Modal.Body className="modal-helper">

                        <Helmet>
                            <title>Affiliate - Original Crash Game</title>
                        </Helmet>
                        {/* <div style={{ background: 'rgba(41,71,144,0.8)', backgroundSize: 'cover'}} className={'p-2 wheel-content'}> */}
                        {/* <Terms/> */}
                        <h4 className={'text-center text-upper text-warning font-weight-bold'}>  Invite your friends to get Bonus.</h4>
                        {/* <p className={'text-center text-upper text-white'}>
                        Invite your friends to get Bonus.
                    </p> */}
                        <Row>
                            <Col sm={12} xl={8} md={8} className={'m-auto'}>
                                <Card>
                                    <Card.Body className={'rounded text-center shadow'} style={{ background: "rgba(41,71,144,0.9)" }}>
                                        <label className="text-white">Your Referral Link (Copy & Share)</label>
                                        {logged ?
                                            <div className="input-group" style={{ border: "none" }}>
                                                <div className="input-group-append">
                                                    <span onClick={e => this.copyAddress('code')} className="input-group-text bgin cpt">
                                                        <i className="far fa-copy" />
                                                    </span>
                                                </div>
                                                <input type="text" value={base_ref_url} id={'code'} className={'form-control text-center text-white'} style={{ background: "rgba(40,44,49,255)", fontWeight: "500" }} />
                                            </div>
                                            :
                                            <input type="text" value={"Please login to see your code."} className={'form-control text-white text-center'} style={{ background: "rgba(40,44,49,255)" }} />
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        {/* </div> */}
                        <Row>
                            <Col sm={12} xl={12} md={12} className={'mx-auto mt-3'}>
                                <Card>
                                    <Card.Body className={'p-0'}>
                                        {!logged ?
                                            <div className="text-center">[ Login to see your stats ]</div>
                                            :
                                            <List />
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}