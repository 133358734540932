import React from 'react';  
// import './Static/css/bootstrap.min.css'; 
import Carousel from 'react-bootstrap/Carousel';  
    
export default function App() {  
  return (  
    <div style={{ display: 'block' }}>
      <Carousel controls={false} indicators={false}>  
        <Carousel.Item interval={4000}>  
          <img className="carous_border d-block w-100" src="/assets/images/win-banner.png" alt="kapamia_wins"  /> 
        </Carousel.Item>  

        <Carousel.Item interval={5000}>  
          <img className="carous_border d-block w-100" src="/assets/images/bonus.gif" alt="kapamia_bonus"  /> 
        </Carousel.Item> 

        {/* <Carousel.Item interval={1500}>  
          <img  
            className="d-block w-100"  
              src="https://play.pakakumi.com/slide-invite-friends.jpg"  
            alt="Image Two"  
          />
        </Carousel.Item>   */}
      </Carousel>  
    </div>  
  );  
}  