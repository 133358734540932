import React from 'react'
import { Dropdown } from "react-bootstrap";
import Login from "../../Auth/Login";
import Chat from "./../Chat";
import Logo from "./../Logo";
import Register from "../../Auth/Register";
import {Event, BRAND} from "../../../../Helper";


var show = true;

class Header extends React.Component {
    collapse = () =>{
        show = !show;
        Event.emit('toggle_sidebar', show);
    }
    render() {
        const { t } = this.props;
        return (
                        <div className="col-12 topbar" id={'topbar'}>
                            <div>
                            <Logo brand={BRAND} />
                            <nav className="navbar-custom">
                            <ul className="list-unstyled topbar-nav float-right mb-0 mt-1 ml-auto">
                            <li className={"guest-dropdown mt-1"}>
                                 <Login t={t} />
                            </li>
                            <li className={"guest-dropdown mt-1"}>
                                <Register t={t} />
                                
                            </li>
                            {/* <li className={"chat-sidebar"}>
                                        <Chat t={t} location={this.props.location} />
                                    </li> */}
                            
                            </ul>
                            </nav>
                            </div>
                           
                            
                        </div>
        );
    }
}

export default Header;